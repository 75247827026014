:root {
    --dark-bg: #121212;
    --darker-bg: #0a0a0a;
    --even-darker-bg: #1a1a1a;
    --neon-green: #00FF7F;
    --neon-blue: #00FFFF;
    --light-text: #e0e0e0;
    --base-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    --gradient-bg: linear-gradient(135deg, var(--darker-bg), var(--even-darker-bg));
}

:root[data-personality="default"] {
    --primary-bg: var(--dark-bg);
    --secondary-bg: var(--darker-bg);
    --highlight-color: var(--neon-green);
    --text-color: var(--light-text);
    --gradient-style: var(--gradient-bg);
}

body {
    display: flex;
    justify-content: center; /* centers horizontally */
    align-items: center;     /* centers vertically */
    height: 100vh;           /* occupies full viewport height */
    margin: 0;
    background: var(--dark-bg);
    font-family: var(--font-family, Arial, sans-serif);
    overflow-x: hidden;
}

.top-menu, .menu-options {
    background: var(--gradient-style);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.top-menu {
    background-color: var(--secondary-bg);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5em;
    color: white;
    position: sticky;
    top: 0;
    z-index: 10;
}

.menu-icon {
    font-size: 24px;
    cursor: pointer;
    z-index: 1001;
}

.menu-logo {
    height: 60px; /* Adjust as necessary */
    margin-right: 5px;
}

.menu-title, .menu-options, .menu-options a {
    color: var(--highlight-color);
}

.menu-title {
    font-weight: bold;
    font-size: 1.2rem;
    padding-left: 0;
    padding-right: 8px;
    margin: 0;
    align-self: center;
    color: var(--neon-blue);
    background-color: transparent;
}

.menu-options, .menu-options a {
    color: var(--neon-blue);
}

.menu-options {
    position: absolute;
    right: 0;
    top: 100%;
    background-color: #333;
    color: white;
    border: 1px solid #444;
    border-radius: 5px;
    z-index: 20;
}

.menu-options a {
    display: block;
    padding: 8px 16px;
    text-decoration: none;
}

.menu-options a:hover {
    background-color: var(--highlight-color);
}

.personality-select {
    width: 250px;
    height: 25px;
    align-self: center;
    margin: 0 8px;  /* Adjust as needed for spacing */
    border: 1px solid var(--neon-blue);
    border-radius: 20px;
    box-shadow: 0 0 3px var(--neon-blue);
    outline: none;
    text-align: center;
}

.avatar {
    border-radius: 25%;
    z-index: 1;
    width: 30px;
    height: 30px;
    position: absolute;
    bottom: -5px;
}

.main-container {
    width: 90%;
    max-width: 480px;
    display: flex;
    flex-direction: column;
    height: 95vh;
    background-color: var(--secondary-bg);
    background-image: url('../images/background.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    color: var(--text-color);
    padding: 12px;
    margin: 0 auto;
    border: 1px solid #333;
    box-shadow: var(--base-shadow);
    overflow-y: auto;
}

.message-list {
    height: 500px;
    flex: 1;
    padding: 2px;
    margin-bottom: 10px;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.message-list::-webkit-scrollbar {
    width: 0;  /* Completely hide the scrollbar */
}

/* Input related styles */
.input-layout, .image-message-layout, .item-assistant-image-message {
    padding: 2px;
    display: flex;
    opacity: 0.95;
}

.input-layout input {
    border: 1px solid var(--neon-green);
    flex: 1;
    margin-right: 8px;
    padding: 10px;
    border-radius: 20px;
    box-shadow: 0 0 3px var(--neon-green);
    outline: none;
}

.input-layout input:focus {
    box-shadow: 0 0 5px rgba(0, 255, 127, 0.5), 0 0 10px rgba(0, 255, 127, 0.5);
}

.input-layout button, .signin-container button {
    background-color: var(--neon-green);
    border: none;
    cursor: pointer;
    padding: 10px 20px;
    border-radius: 20px;
    box-shadow: var(--base-shadow);
    transition: background-color 0.3s ease;
}

.input-layout button:hover, .signin-container button:hover {
    background-color: var(--neon-green);
}

.input-layout {
    flex-shrink: 0;
}

.google-signin-btn {
    display: flex;
    align-items: center;
    background-color: white;
    padding: 10px 15px;
    border-radius: 4px;
    margin: 5px;
    border: none;
    cursor: pointer;
    transition: box-shadow 0.3s;
}

.google-signin-btn:hover {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.google-signin-btn img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.google-signin-btn:active {
    background-color: #286ABD;
}

.signin-container {
    min-width: 350px;
    max-width: 425px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: var(--secondary-bg);
    background-image: url('../images/background.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
    opacity: 1;
    transition: opacity 1s ease-out;
}

.signin-container p {
    color: white;
    text-align: center;
    margin-top: 20px;
}

.signin-container.fade-out {
    opacity: 0;
}

.signin-container input[type="email"], .signin-container input[type="password"], .signin-container button {
    width: 80%;
    padding: 10px 20px;
    margin: 8px 0;
    font-size: 1rem;
    border-radius: 20px;
    border: none;
    outline: none;
    transition: transform 0.2s ease;
}

.signin-container input[type="email"]:focus, .signin-container input[type="password"]:focus {
    transform: scale(1.05);
    box-shadow: 0 0 5px var(--neon-green);
}

.signin-container button {
    cursor: pointer;
    color: white;
    background-color: var(--neon-green);
    box-shadow: 0 0 5px var(--neon-green);
}

.signin-container button:hover {
    transform: scale(1.05);
    background-color: var(--neon-blue);
    box-shadow: 0 0 5px var(--neon-blue);
}

.signin-title {
    color: var(--neon-blue);
    font-size: 2.5rem;
    margin-bottom: 20px;
    text-shadow: 1px 1px 2px #000;
}

.signin-logo {
    width: 150px; /* Adjust based on your logo size */
    margin-bottom: 20px;
}

.image-message-layout img, .item-assistant-image-message img {
    max-width: 100%;
    margin-bottom: 16px;
}

.image-message-layout img {
    height: auto;
    max-height: 300px;
}

.item-user-message .avatar {
    right: -5px;
}

.item-assistant-message .avatar {
    left: -5px;
}

.item-assistant-message, .item-user-message {
    padding: 2px;
    display: flex;
    align-items: flex-end;
    position: relative;
}

.item-assistant-message .text-message, .item-user-message .text-message {
    background-color: var(--darker-bg);
    color: var(--light-text);
    margin: 5px 0;
    padding: 16px;
    border-radius: 16px;
    max-width: calc(85% - 45px);
    opacity: 0.85;
    z-index: 0;
    position: relative;
}

.item-assistant-message .text-message {
    order: 1;
    border: 1px solid var(--neon-blue);
    box-shadow: 0 0 5px var(--neon-blue);
    padding: 10px 15px;
    border-radius: 15px 15px 15px 0;
    display: inline-block;
    margin: 5px;
    max-width: 85%; /* Ensures long messages don't span the entire width */
    align-self: flex-start; /* Align to the left */
}

.item-user-message {
    flex-direction: row-reverse;
    text-align: right;
}

.item-user-message .text-message {
    order: 2;
    border: 1px solid var(--neon-green);
    box-shadow: 0 0 5px var(--neon-green);
    padding: 10px 15px;
    border-radius: 15px 15px 0 15px; /* Top left, top right, bottom right, bottom left */
    display: inline-block;
    margin: 5px;
    max-width: 85%; /* Ensures long messages don't span the entire width */
    align-self: flex-end; /* Align to the right */
}

.text-message:hover {
    box-shadow: 0 0 10px rgba(0, 255, 127, 0.8), 0 0 20px rgba(0, 255, 127, 0.8);
}

.item-assistant-message .text-message:hover {
    box-shadow: 0 0 10px rgba(0, 255, 255, 0.8), 0 0 20px rgba(0, 255, 255, 0.8);
}

.item-assistant-message {
    text-align: left;
    flex-direction: row;  /* This will push the content to the left */
}